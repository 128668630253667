import ApiService from './ApiService'

export async function apiSignIn(data) {
    var form_data = new FormData()

    for (var key in data) {
        form_data.append(key, data[key])
    }

    return ApiService.fetchData({
        url: '/admin/login',
        method: 'post',
        data: form_data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/admin/logout',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
